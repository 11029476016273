.map-container {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  height: 500px;
  width: 500px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.map {
  height: 500px;
  width: 500px;
}

.btn-location {
  position: absolute;
  left: 0.7em;
  bottom: 0.7em;
  z-index: 1000;
  background-color: #fff;
  border-radius: 8px;
  padding: 0.4em 0.5em;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  transition: opacity 0.2s;
}

.btn-location:hover {
  opacity: 0.8;
}

.leaflet-control-attribution.leaflet-control {
  display: none;
}

.leaflet-control-attribution {
  display: none;
}
