body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #000000 !important;
  text-decoration: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary-color: rgb(144, 45, 60);
  --secondry-color: #898892;
}

.bg-primary {
  background: var(--primary-color);
}

.bg-secondry {
  background: var(--secondry-color);
}

.card-data-grid .MuiCardContent-root,
.css-sst63a-MuiDialogContent-root {
  padding: 0 !important;
}
