.MuiDrawer-root {
  .MuiDrawer-paper {
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
    overflow-x: hidden !important;
    width: calc(56px + 1px) !important;
    margin-top: 70px !important;
    margin-left: 10px !important;
    border: 1px solid #b9b9b9 !important;
    border-radius: 10px !important;
    height: calc(100% - 80px) !important;
  }
}

.MuiListItem-root {
  width: calc(100% - 1rem) !important;
  margin: 0.5rem !important;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  background-color: #f7f7f7;
  overflow: hidden;
}

.menu-icon {
  color: #636e72;
}

.active-list-item {
  background-color: #d63031 !important;
}

.css-je3nfp {
  border-radius: 10px !important;
  overflow: hidden;
}
