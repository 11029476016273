.stepper-register
  .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  background-color: rgba(255, 255, 255, 0.6470588235);
  border-radius: 10px;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.css-2dum1v-MuiDrawer-docked .MuiDrawer-paper {
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  overflow-x: hidden;
  width: 57px;
  margin-top: 70px;
  margin-left: 10px;
  border: 1px solid #b9b9b9;
  border-radius: 10px;
  height: calc(100% - 80px) !important;
}

.css-1hsz34s-MuiDrawer-docked .MuiDrawer-paper {
  margin-top: 70px;
  margin-left: 10px;
  border: 1px solid #b9b9b9;
  border-radius: 10px;
  height: calc(100% - 80px) !important;
}

.css-uxa1ks-MuiListItem-root {
  width: calc(100% - 1rem) !important;
  margin: 0.5rem !important;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  background-color: #f7f7f7;
  overflow: hidden;
}

.menu-icon {
  color: #636e72;
}

.active-list-item {
  background-color: #d63031 !important;
}

.css-je3nfp {
  border-radius: 10px !important;
  overflow: hidden;
}
/*# sourceMappingURL=style.css.map */
